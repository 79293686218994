<template>
    <div>
        <Header :id="id" :exhibitionId="exhibitionId"></Header>
        <div class="main">
            <div class="info">
                <div class="left">
                    <div class="left1">
                        <div class="left2">
                            <div class="bigimg" ref="bigimg">
                                <img :src="productList.thumb" alt="" id="big" v-show="bigImgShow">
                                <video :src="productList.videoUrl" controls="controls" id="big" style="width:100%" v-show="bigVideoShow"></video>
                            </div>
                            <div class="smallimg">
                                <div class="changeimg" v-for="(item,index) in productList.images" :key="index">
                                    <img :src="item.url" alt="" @click="srcChange(item.url, 1)" v-if="item.type == 1">
                                    <img :src="item.thumb" alt="" @click="srcChange(item.url, 2)" v-else-if="item.type == 2">
                                </div>
                            </div>
                        </div>
                        <div class="right2">
                            <div class="rinfo1">{{ productList.productName }}</div>
                            <div class="rinfo2">
                                <div class="rtit1">
                                    {{ $t('FOBPrice') }}: 
                                    <template v-if="productList.minPrice || productList.maxPrice">
                                    {{ productList.minPrice }} <template v-if="productList.maxPrice">~</template> {{ productList.maxPrice }}
                                    </template>
                                    <template v-else>{{ $t('Negotiable') }}</template>
                                </div>
                                <div class="rtit2">{{ $t('Min.Order') }}: {{ productList.minOrder }}</div>
                            </div>
                            <div class="rtit3">
                                <div class="rleft3">{{ $t('PaymentMethod') }} :</div>
                                <div class="rright3">{{ productList.payMethod }}</div>
                            </div>
                            <div class="rinfo4">
                                <div class="rtxt1">{{ $t('Keywords') }} :</div>
                                <div class="rtxt3">
                                    <template v-for="(item,index) in productList.keywords">
                                        <div class="rtxt2" :key="index" v-if="item">{{item}}</div>
                                    </template>
                                </div>
                            </div>
                            <div class="rinfo5">
                                <div class="rleft5">
                                    <a-icon type="mail" />
                                    {{ $t('contact.now') }}
                                </div>
                                <div class="rright5">
                                    <a-icon type="message" />
                                </div>
                            </div>
                            <div class="rinfo6">
                                <div @click="addFavorites" v-if="productList.isLike == 0">
                                    <a-icon type="heart" /> &nbsp;
                                    {{ $t('Favorites') }}
                                </div>
                                <div @click="removeFavorites" v-else style="color:#D43A17">
                                    <a-icon type="heart" style="color:#D43A17" /> &nbsp;
                                    {{ $t('removeFavorites') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="larea">
                         <a-tabs default-active-key="1" @change="callback">
                            <a-tab-pane key="1" :tab="$t('ProductDetails')">
                                <div class="one" v-html="productList.productDesc"></div>
                            </a-tab-pane>
                            <a-tab-pane key="2" :tab="$t('CompanyProfile')" force-render>
                                <div class="one" v-html="conmpany.companyDesc"></div>
                            </a-tab-pane>
                        </a-tabs>
                    </div>
                    <div class="now">
                        <div class="now1">
                            <div>{{ $t('contact.now') }}</div>
                        </div>
                        <div class="now2">
                            <div class="cant1">
                                <div class="caleft">{{ $t('To') }}:</div>
                                <div class="caright">{{conmpany.companyName}}</div>
                            </div>
                            <div class="cant1">
                                <div class="caleft"><span>*</span>{{ $t('Subject') }}:</div>
                                <div class="caright">
                                    <input type="text" class="subject" v-model="subject" />
                                </div>
                            </div>
                            <div class="cant1">
                                <div class="caleft"><span>*</span>{{ $t('Purchasequantity') }}:</div>
                                <div class="caright">
                                    <input type="number" class="quantity" v-model="number" />
                                    <a-select default-value="" style="width: 260px;height:40px" @change="handleChange">
                                        <a-select-option value="">
                                            {{ $t('Please.select') }}
                                        </a-select-option>
                                        <a-select-option :value="$t('platform')">
                                           {{ $t('platform') }}
                                        </a-select-option>
                                        <a-select-option :value="$t('only')">
                                           {{ $t('only') }}
                                        </a-select-option>
                                        <a-select-option :value="$t('individual')">
                                           {{ $t('individual') }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </div>
                             <div class="cant1">
                                <div class="caleft"><span>*</span>{{ $t('contact') }}:</div>
                                <div class="caright textarearight">
                                    <textarea name="" v-model="concant"></textarea>
                                </div>
                            </div>
                             <div class="cant1">
                                <div class="caleft"></div>
                                <div class="caright">
                                   <button class="submit" @click="regsub()">{{ $t('submit') }}</button>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="right">
                    <div class="list1">
                        <div>{{ $t('contact.exhibitor') }}</div>
                    </div>
                    <div class="list2">
                        <div>{{conmpany.companyName}}</div>
                    </div>
                    <div class="list3">
                        <div class="l-left">
                            <a-icon type="user" />
                        </div>
                        <div class="l-right">{{conmpany.liaison.concat}}</div>
                    </div>
                    <div class="list3">
                        <div class="l-left">
                            <a-icon type="mail" />
                        </div>
                        <div class="l-right">{{conmpany.liaison.email}}</div>
                    </div>
                    <div class="list3">
                        <div class="l-left">
                            <a-icon type="global" />
                        </div>
                        <div class="l-right">{{conmpany.country}}</div>
                    </div>
                    <div class="list3">
                        <div class="l-left">
                            <a-icon type="environment" />
                        </div>
                        <div class="l-right">{{conmpany.address}}</div>
                    </div>
                    <div class="list4">{{ $t('Send.cards') }}</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "Productdetail",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
        productId: this.$route.params.productId,
        // companyId: this.$route.params.companyId,
        exhibitionId: this.$route.params.exhibitionId,
        id: this.$route.params.id,
        productList: [],
        conmpany: {
            liaison: ''
        },
        subject: '',
        number: 0,
        unit: '',
        concant: '',
        token: localStorage.token,
        bigImgShow: true,
        bigVideoShow: false
    }
  },
   mounted(){
    if (this.token == '') {
        this.$layer.msg('请先登录');
        this.$router.go(-1)
    }
    this.getList()
    this.getConmpany()
  },
  methods: {
    srcChange(src, type){
        // 图片
        if (type == 1) {
            this.bigImgShow = true
            this.bigVideoShow = false
            this.productList.thumb = src
        } else if (type == 2) { // 视频
            this.bigImgShow = false
            this.bigVideoShow = true
            this.productList.videoUrl = src
        }
    },
    callback(key) {
        console.log(key);
    },
    // 获取商品详情
    getList () {
       this.$common.fetchList('/company/product/detail/'+this.productId, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.productList = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 获取公司详情
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.conmpany = result
          console.log(result)
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    handleChange(value) {
      this.unit = value;
    },
    // 添加收藏夹
    addFavorites () {
        this.$common.handlePost('index/product/like', { productId: this.productId }).then(data => {
            if (data.code === 200) {
                this.$message.success(data.msg)
                this.getList()
            } else {
                this.$message.error(data.msg)
            }
        })
    },
    // 取消收藏
    removeFavorites () {
        this.$common.handlePost('index/product/unlike', { productId: this.productId }).then(data => {
            if (data.code === 200) {
                this.$message.success(data.msg)
                this.getList()
            } else {
                this.$message.error(data.msg)
            }
        })
    },
    // 提交采购信息
    regsub() {
        if (this.subject == '') {
            this.$layer.alert("项目名称不能为空");
            return 
        }
        if (this.number == 0) {
            this.$layer.alert("采购数量不能为0");
            return 
        }
        if (this.unit == '') {
            this.$layer.alert("请选择数量单位");
            return 
        }
        if (this.concant == '') {
            this.$layer.alert("联系人信息不能为空");
            return 
        }
        const params = {
            supplierId: this.id,
            subject: this.subject,
            purchaseQuantity: this.number+this.unit,
            concatInfo: this.concant
        }
        this.$common.handlePost('/company/purchase/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'Supplier', params: { id: id } })
        } else {
             this.$layer.alert(data.msg);
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spproductdetail.less';
  .rinfo6{
      div{
          cursor: pointer;
      }
  }
</style>
